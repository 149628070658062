.npabanner{
    background-image: url("../../assets/ad.png");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 6% 0%;
}
.npabanner h2{
    font-family: 'Poppins', sans-serif;
    /* color: #003471; */
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    background: #00000038;
    padding: 8px 0px;
}
.virutual1{
    background: #003471;
    border-radius: 50px 0px 50px 0px;
}
.virutual1 h3{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 600;
}
.virutual1 p{
    font-family: 'Poppins', sans-serif;
    margin-top: 4%;
    color: white;
}
@media only screen and (max-width: 767px) {
    .virutual1{
        border-radius: 0px 0px 0px 0px;
    }
  }