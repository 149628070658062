.aboutusbanner{
    background-image: url("../../assets/aboutus.png");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 5% 0%;
}
.aboutusbanner h2{
    font-family: 'Poppins', sans-serif;
    /* color: #003471; */
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: white;
    background: #00000070;
    padding: 8px 0px;
}


.portfolio{
    float:left;
    width:100%;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    padding:30px 0;
    position:relative;
    font-family:'Manrope';
  }
  .portfolio .item{
    float:left;
    width:300px;
    overflow:hidden;
    border-radius:11px;
    border:1px solid #ddd;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .portfolio .item .thumb{
    float:left;
    width:100%;
    overflow:hidden;
    position:relative;
  }
  .portfolio .item .thumb img{
    float:left;
    width:100%;
    height:auto;
    object-fit:cover;
    object-position:center;
    aspect-ratio:1/1;
    transition:0.6s;
  }
  .portfolio .item:hover .thumb img{
    transform:scale(1.1);
  }
  .portfolio .item .thumb a.category{
    position:absolute;
    width:auto;
    padding:7px 14px;
    background:pink;
    color:#000;
    left:20px; top:15px; border-radius:20px;
    font-size:10px;
    z-index:6;
    transition:0.6s;
    text-transform:uppercase;
  }
  .portfolio .item:hover .thumb a.category{
    top:15px;
  }
  .portfolio .item .thumb a.galleryimage{
    position:absolute;
    width:100%; height:100%;
    left:0; top:0;
    z-index:6;
    color:#fff;
    font-size:21px;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    opacity:0;
    transition:0.6s;
  }
  .portfolio .item:hover .thumb a.galleryimage{
    opacity:1;
  }
  .portfolio .item .text{
    float:left; width:100%;
    padding:20px;
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .portfolio .item .text h3{
    color:#000;
    font-size:19px;
    margin:0 0 2px 0;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  .portfolio .item .text h3 a{
    color:#000;
    border-bottom:1px solid rgba(0,0,0,0);
    transition:0.6s;
    text-decoration: none;
  }
  .portfolio .item .text h3:hover a{
    border-bottom:1px solid rgba(0,0,0,1);
  }
  .portfolio .item .text p{
    color: black;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 15px 0;
  }
  .portfolio .item .text a.view{
    display:table;
    width:auto; height:auto;
    padding:10px 25px;
    background:pink;
    color:#000;
    font-size:14px;
    border-radius:20px;
    transition:0.6s;
  }
  .portfolio .item .text a.view:hover{
    background:#000;
    color:#fff;
  }
  .portfolio .item .text a.view span{
    margin-left:6px;
  }
  p.brandtext{
    float:left;
    width:100%;
    margin:10px 0 0 0;
    color:#555;
    font-size:11px;
    text-align:center;
  }
  p.brandtext a{
    color:#000;
    border-bottom:1px solid pink;
  }

  .founders{
    background-color: rgb(0 52 113);
    padding:2% 0%;
  }
  .founders h2{
   text-align: center;
   color: white;
   font-family: 'Poppins', sans-serif;
   font-weight: 700;
  }
  .founders h6{
    font-family: 'Poppins', sans-serif;
    color: #1d6fcf;
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    font-weight: 500;
  }
  .founders .paddfounder{
    padding: 30px 0px 135px 0px !important;

  }


/* advisor */
.advisor{
    padding: 3% 0%;
}
.advisor h2{
    font-family: 'Poppins', sans-serif;
    color: #003471;
    text-align: center;
    font-weight: 700;
    margin-bottom: 2%;
}




  figure.snip1390 {
    font-family: 'Poppins', sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    /* min-width: 230px;
    max-width: 315px; */
    width: 100%;
    color: #000000;
    text-align: center;
    font-size: 16px;
    background-color: #1f72d4;
    padding: 30px;
    background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px 40px 10px 40px;
  }
  figure.snip1390 *,
  figure.snip1390 *:before,
  figure.snip1390 *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  figure.snip1390 figcaption {
    width: 100%;
  }
  figure.snip1390 h2,
  figure.snip1390 h4,
  figure.snip1390 blockquote {
    margin: 0;
  }
  figure.snip1390 h2,
  figure.snip1390 h4 {
    font-weight: 300;
  }
  figure.snip1390 h2 {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
  }
  figure.snip1390 h4 {
    color: #a6a6a6;
    font-size: 18px;
  }
  figure.snip1390 blockquote {
    font-size: 15px;
    padding: 45px 20px 40px 50px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
    text-align: left;
    position: relative;
    font-family: 'Poppins', sans-serif;
  }
  figure.snip1390 blockquote:before {
    font-family: 'FontAwesome';
    content: "\201C";
    position: absolute;
    font-size: 70px;
    opacity: 0.25;
    font-style: normal;
    top: 0px;
    left: 20px;
  }
  figure.snip1390 .profile {
    width: 100px;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    border: solid 5px #8eb5e3;
  }
  




  