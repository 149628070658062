@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Poppins:wght@500&display=swap');

.servicebanner{
    background-image: url("../../assets/blue.png");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 5% 0%;
}
.servicebanner h2{
    font-family: 'Poppins', sans-serif;
    color: #003471;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
}
.services{
    /* margin: 3% 0%; */
    padding: 3% 0%;
}
.services1{
    /* margin: 3% 0%; */
    /* background-image: linear-gradient(#ecf6f9, #9dcae3, #9fd5fb, #daf3ff); */
    padding: 3% 0%;
    background-color: #e0f2fd;
    /* border-radius: 40px 0px 40px 0px; */
    border-radius: 100px 0px 100px 0px;
}
.services h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
}
.services1 h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
}
.services1 h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 25px;
}
.services p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.services1 p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.services .col-md-6{
    margin-top: 2%;
}
/* .backgroundcolor{
    background-image: linear-gradient(#ecf6f9, #9dcae3, #9fd5fb, #daf3ff);
} */
.services img{
    border-radius: 12px;
}



@media only screen and (max-width: 767px) {
    .services1{
        border-radius: 0px 0px 0px 0px;
    }
  }