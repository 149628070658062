.virutual{
    padding: 4% 0%;
}
.virutual h3{
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: 700;
}
.virutual p{
    font-family: 'Poppins', sans-serif;
    margin-top: 4%;
}
.virutualbanner{
    background-image: url("../../assets/viru.png");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 7% 0%;
}
.virutualbanner h2{
    font-family: 'Poppins', sans-serif;
    /* color: #003471; */
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: white;
    background: #00000070;
    padding: 8px 0px;
}