.parnerbanner{
    background-image: url("../../assets/Partner-with-Us.jpg");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 7% 0%;
}
.parnerbanner h2{
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    background: #00000070;
    padding: 8px 0px;
    font-family: 'Poppins', sans-serif;
}
.partner-p{
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
}
.partner-last{
    text-align: center;
}
.partners{
    background: #1668af26;
    border-radius: 100px 0px 100px 0px;
}


@media only screen and (max-width: 767px) {
    .partners{
        border-radius: 0px 0px 0px 0px;
    }
  }

/* .contact2 h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}
.contact2 .form-container {
    display: flex;
}
.contact2 .left-container {
    flex: 1;
    height: 480px;
}
.contact2 .right-container {
    display: flex;
    flex: 1;
    height: 460px;
    background-color: white;
    justify-content: center;
    align-items: center;
}
.contact2 .left-container {
    display: flex;
    flex: 1;
    height: 480px;
    justify-content: center;
    align-items: center;
    color: white;
}
.contact2 .left-container p {
    font-size: 0.9rem;
}
.contact2 .right-inner-container {
    width: 70%;
    height: 80%;
    text-align: center;
}
.contact2 .left-inner-container {
    height: 50%;
    width: 80%;
    text-align: center;
    line-height: 22px;
}
.contact2 input, textarea {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    font-size: 0.8rem;
}
.contact2 input:focus, textarea:focus {
    outline: 1px solid teal;
}
.contact2 button {
    border-radius: 20px;
    border: 1px solid #00b4cf;
    background-color: #00b4cf;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
}
.contact2 button:hover {
    opacity: 0.7;
}
@media only screen and (max-width: 600px) {
    .contact2 .left-container {
        display: none;
   }
   .contact2 .lg-view {
        display: none;
   }
}
@media only screen and (min-width: 600px) {
    .contact2 .sm-view {
        display: none;
   }
}
.contact2 form p {
    text-align: left;
} */
