.home{
    padding: 70px 0px;
}
.home .homepara{
    font-size: 20px;
    color: black;
}
.home .home1h6{
    font-size: 23px;
    font-weight: 700;
}




.aboutus{
    background-image: url("../../assets/about.png");  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 8% 0%;
}
.aboutus-text{
    background-color: white;
    padding: 20px 40px;
    margin-bottom: 0px !important;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 3% 0% !important;
}
.aboutus h2{
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.btn-success{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    margin: 1% 0%;
        color: #fff;
        background-color: hsl(136.52deg 100% 34.9%);
        border-color: hsl(136.52deg 100% 34.9%);
}
.card{
    height: 280px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: 90%;
}
.card-img-top{
    width: 100%;
    height: 180px;
}
.wealth{
    background-image: url("../../assets/cfo.png");  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 6% 0%;
}
.wealth h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: white;
    background-color: #00000030;
    padding: 10px 0px;
}
.servicess{
    padding: 3% 0%;
}
.servicess h2{
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.slidertop{
    padding:3% 0%;
}
/* .slick-prev {
    color: #0f9d58 !important;
    background: #0f9d58 !important;
} */
.slick-prev {
    left: -18px !important;
    z-index: 7777;
}
.slick-next {
    right: 30px !important;
    z-index: 7777;
}
.slick-prev:before {
    font-size: 30px!important;
    transition: .5s;
}

.slick-next:before {
    font-size: 30px!important;
    transition: .5s;
}
.slick-prev:before {
    color: #0fff00!important;
}
.slick-next::before {
    color: #0fff00!important;
}
.card-title{
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.advisory{
    background-color: hsl(209.52deg 100% 24.71%);
}
.advisory h2{
    color: white !important;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}


@media only screen and (max-width: 600px) {
    .slick-prev {
        left: -12px !important;
        z-index: 7777;
    }
    .slick-next {
        right: 5px !important;
        z-index: 7777;
    }
    .btn-success{
        margin: 4% 0%;
    }
    .card{
        width: 100%;
    }
  }