.contactbanner{
    background-image: url("../../assets/contact-us-banner1.jpg");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 7% 0%;
}
.contactbanner h2{
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    background: #00000070;
    padding: 8px 0px;
    font-family: 'Poppins', sans-serif;
}


.iconzz{
    position: absolute;
    top: 18px;
    left: 20px;
    font-size: 20px;
    color: #003471;
}

.virutual form {
    /* min-width: 25rem; */
}
.virutual form .title {
    font-family: 'Poppins', sans-serif;
    color: #212529;
    font-size: 2.3rem;
}
.virutual form .form-control {
    background-color: #f2f6f8;
    border-radius: 2rem;
    border: none;
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}
.virutual form .form-control.thick {
    height: 3.3rem;
    padding: 0.5rem 3.5rem;
    margin-top: 3%;
    font-family: 'Poppins', sans-serif;
}
.virutual form .form-control:focus {
    background-color: #f2f6f8;
    border: none;
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}
.virutual form .message .form-control {
    padding: 0.5rem 1.8rem;
}
.virutual form ::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #838788;
    position: relative;
    left: 0;
}
.virutual form input, form textarea {
    font-family: 'Poppins', sans-serif;
    color: #212529;
    font-size: 1.1rem;
}
.virutual form .icon {
    color: #57565c;
    height: 1.3rem;
    position: absolute;
    left: 1.5rem;
    top: 1.1rem;
}
.virutual .btn.btn-primary {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 3rem;
    border: 0;
    border-radius: 3rem;
    /* background-image: linear-gradient(131deg, #ffd340, #ff923c, #ff923c, #ff923c); */
    background-image: linear-gradient(131deg, #003471, #003471, #003471, #003471);
    background-size: 300% 100%;
    transition: all 0.3s ease-in-out;
    margin-top: 5%;
}

.virutual .btn.btn-primary:hover:enabled {
    box-shadow: 0 0.5em 0.5em -0.4em #ff923c ba;
    background-size: 100% 100%;
    transform: translateY(-0.15em);
}
.virutual textarea.form-control-lg {
    margin-top: 3%;
}
.virutual input[type=text] {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}
.swal-title {
    color: rgb(0 31 68);
    font-family: 'Poppins', sans-serif;
    font-weight: 500 !important;
}
.swal-icon--success__ring {
    border: 4px solid hsl(98deg 100% 28.17% / 66%) !important;
}
.swal-button {
    background-color: #001f44 !important;
}
.virutual .errormsg{
    color: red;
    margin-top: 10px !important;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0px !important;
    margin-left: 15px;
}
.rc-anchor-light {
    margin-left: 10px;
    margin-top: 8px;
}
