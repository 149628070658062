.caption-text{
    background-color: hsl(219.09deg 31.13% 41.57% / 93%);
    color: white;
    padding: 20px 0px;
    width: 60%;
    font-family: 'Poppins', sans-serif;
}
.carousel-caption {
    bottom: 35% !important;
}
.carousel-caption p{
    font-size: 24px;
    margin-bottom: 0px !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}


@media only screen and (max-width: 600px) {
    .carousel img{
        height: 240px;
    }
    .caption-text{
        background-color: hsl(219.09deg 31.13% 41.57% / 93%);
        color: white;
        padding: 10px 10px;
        width: 100%;
        font-family: 'Poppins', sans-serif;
    }
    .carousel-caption p{
        font-size: 14px;
        margin-bottom: 0px !important;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }
  }