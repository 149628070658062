.bg-light{
    background-color: hsl(212.39deg 100% 22.16%) !important;
}
.navbar-light .navbar-nav .nav-link {
    color: white !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
.navbar-expand-lg .navbar-nav .nav-link {
    /* padding-right: 2rem !important;
    padding-left: 2rem !important; */
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
}
.navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    justify-content: end !important;
}
.navbar-toggler {
    background-color: white !important;
}
.navbar-expand-lg .navbar-collapse {
    justify-content: center;
}
.dropdown-item {
    font-family: 'Poppins', sans-serif !important;
}
.dropdown-item:hover {
    color: #ffffff !important;
    background-color: #003471 !important;
}

.navbar-nav{
  align-items: center;
}

.navbar-nav a{
    font-family: 'Poppins', sans-serif !important;
    color: white;
    font-weight: 500;
    text-decoration: none;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    padding-top: 8px;
    padding-bottom: 8px;
}
.navbar-nav a:hover{
    color: #003471;
    transition: 0.5s;
    background-color: white;
    border-radius: 2px;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #003471!important;
    background: white;
    transition: 0.5s;
    border-radius: 2px;
}
.navbar .active{
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #003471 !important;
    background-color: white;
    font-family: 'Poppins', sans-serif !important;
    text-decoration: none;
    transition: 0.5s;
    border-radius: 2px;
    font-weight: 500;
    margin-left: 2px;
    margin-right: 2px;
}
@media only screen and (max-width: 767px) {
    .heading{
        font-size: 18px;
    }
  }