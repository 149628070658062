.footer{
    background-color: white;
    padding: 25px 0px;
    border-top: 2px solid #00000030;
}
.icon{
    font-size: 30px;
    background-color: hsl(48deg 97.83% 63.92%);
    color: black;
    padding: 3px;
    border: 1px solid hsl(48deg 97.83% 63.92%);
    border-radius: 50px;
}
.icon:hover {
    transform: scale(1.3);
    transition: .5s;
}
.footer h6{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}
.footer2 {
    background-color: black;
    color: white;
    font-weight: 500;
    padding: 10px 0px;
    text-align: center;
}
.copright{
    margin-bottom: 0px !important;
}
.footer li{
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.footer ul a{
   color: black !important;
   text-decoration: none;
}
.footer ul a:hover{
    color: #005283 !important;
    transition: 0.5s;
    text-decoration: none;
 }
.footer li:hover{
   color: #003471;
   transition: 0.5s;
}
.footer p{
    font-family: 'Poppins', sans-serif;
}

.footer .image {
    animation: 1.3s infinite glowing1;
}
.footer .image {
    width: 45px;
    height: 45px;
    /* background: #1c87c9; */
    background: #16b707;
    border-radius: 50%;
    position: relative;
}
.footer img {
    width: 28px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.telee{
    background-color: rgb(0 64 126);
    padding: 7px;
    font-size: 34px;
    border-radius: 50px;
    color: white;
    margin-top: 10px;
}
.whatsapp{
    background-color: green;
    padding: 6px;
    font-size: 36px;
    border-radius: 50px;
    color: white;
    margin-top: 10px;
}
/* .socialicon{
    display: none;
} */
.float-sm .float-ap {
    top: 300px;
}
.float-sm .f2-f2 {
    /* background: #eb5b43; */
    background: #5686d7c7;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 4px;
    border: 2px solid #ffffff;
    width: 60px;
    height: 120px;
    border-radius: 25px;
    position: fixed;
    right: 5px;
    z-index: 1000;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    font-family: 'Calibri';
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}
.pointicon{
    display: none;
}
.footer .fonts20p{
    font-size: 20px;
}
@media only screen and (max-width: 767px) {
    .heading{
        font-size: 18px;
    }
    .footer h6{
        margin-top: 30px;
    }
    .socialicon{
        display: block;
    }
    .pointicon{
        display: block;
    }
  }