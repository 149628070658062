.wealthbanner{
    background-image: url("../../assets/wealthbanner.jpg");
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: contain; */
    width: 100%;
    background-size: cover;
    padding: 7% 0%;
}
.wealthbanner h2{
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    background: #00000070;
    padding: 8px 0px;
    font-family: 'Poppins', sans-serif;
}